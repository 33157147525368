import React from "react";
import axios from "axios";
import Icon from "../assets/images/icon.png";
import "../assets/styles/login-styles.css";

const Login = () => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [validCredentials, setValidCredentials] = React.useState(false);

  const usernameHandler = (event) => {
    setUsername(event.target.value);
  };
  const passwordHandler = (event) => {
    setPassword(event.target.value);
  };

  const loginClickHandler = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    try {
      const response = await axios.post("http://localhost:3001/login", {
        username,
        password,
      });
      console.log("Login successful:", response.data);
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  React.useEffect(() => {
    setValidCredentials(username.length > 0 && password.length > 0);
  }, [username, password]);
  return (
    <div className="container">
      <h1 className="title">Forex Price Alerts</h1>
      <h2 className="subTitle">Plus Indices & Crypto</h2>
      <img src={Icon} className="icon" alt="Forex Price Alerts Icon" />
      <h3 className="form-title">Client Log in</h3>
      <input
        className="input"
        id="username"
        type="text"
        placeholder="Email address*"
        onChange={usernameHandler}
      />
      <input
        className="input"
        id="password"
        type="password"
        placeholder="Password*"
        onChange={passwordHandler}
      />
      <button
        className={`button ${!validCredentials ? "button-disabled" : ""}`}
        disabled={!validCredentials}
        onClick={loginClickHandler}
      >
        Login
      </button>
      <p className="text">
        Don't have an account?{" "}
        <a className="link" href="\signup">
          Sign Up
        </a>
      </p>
    </div>
  );
};

export default Login;
