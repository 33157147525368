import React, { useState } from "react";
import axios from "axios";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    fontFamily: "'Roboto', sans-serif",
  },
  input: {
    padding: "10px",
    margin: "10px 0",
    borderRadius: "5px",
    border: "1px solid #ccc",
    width: "250px",
    fontSize: "16px",
  },
  button: {
    padding: "10px 20px",
    borderRadius: "5px",
    border: "none",
    backgroundColor: "#4CAF50",
    color: "white",
    cursor: "pointer",
    fontSize: "16px",
    margin: "10px 0 20px 0",
    opacity: 1, // Ensure full opacity when enabled
  },
  buttonDisabled: {
    backgroundColor: "#ccc",
    color: "#666",
    cursor: "not-allowed",
    opacity: 0.5,
  },
  title: {
    fontSize: "32px",
    color: "#333",
    marginBottom: "20px", // Space between title and form
  },
  text: {
    fontSize: "14px",
    color: "#333",
  },
};

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validCredentials, setValidCredentials] = useState(false);

  const emailHandler = (event) => {
    setEmail(event.target.value);
  };

  const passwordHandler = (event) => {
    setPassword(event.target.value);
  };

  const signUpClickHandler = async (event) => {
    event.preventDefault(); // Prevent default form submission
    try {
      const response = await axios.post("http://localhost:3001/signup", {
        email,
        password,
      });
      console.log("Sign Up successful:", response.data);
      // Handle further actions here like redirecting the user
    } catch (error) {
      console.error("Sign Up failed:", error);
      // Display an error message to the user
    }
  };

  React.useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setValidCredentials(emailRegex.test(email) && password.length > 6);
  }, [email, password]);

  return (
    <div style={styles.container}>
      <div style={styles.title}>Create an account</div>
      <input
        style={styles.input}
        id="email"
        type="email"
        placeholder="Email address*"
        onChange={emailHandler}
        value={email}
      />
      <input
        style={styles.input}
        id="password"
        type="password"
        placeholder="Password*"
        onChange={passwordHandler}
        value={password}
      />
      <button
        style={
          validCredentials
            ? styles.button
            : { ...styles.button, ...styles.buttonDisabled }
        }
        onClick={signUpClickHandler}
        disabled={!validCredentials}
      >
        Continue
      </button>
      <div style={styles.text}>
        Already have an account?{" "}
        <a style={styles.link} href="\">
          Login
        </a>
      </div>
    </div>
  );
};

export default SignUp;
